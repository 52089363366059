@import "./variables.less";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url("../../assets/fonts/Inter.ttf");
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-size: 16px;
  color: @primaryTextColor;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  background-color: rgba(#e8e4d8, 0.2);
  // background-image: url("../../assets/images/mg-thumbprint.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  #root {
    height: 100%;
    margin: auto;
    overflow: auto;
  }

  audio {
    height: 30px;
  }
 
  audio::-webkit-media-controls-panel {
    padding: 0;
    background-color: #d8d4c8;
  }
  audio::-webkit-media-controls-fullscreen-button, audio::-webkit-media-controls-rewind-button {
    display: none;
  }

  * {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
    }

    &::-webkit-scrollbar-track {
      background: #ccc;
    }
  }

  .interactive-progress-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 300px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    transform: translate(-50%, -50%);

    .icon {
      margin-bottom: (@gutter * 0.75);

      .success {
        width: 96px;
        height: 96px;
        transition: all 1.5s ease-in-out;
        color: #00490a;
      }

      .failure {
        width: 96px;
        height: 96px;
        color: #da2c38;
        transition: all 1.5s ease-in-out;
      }
    }
  }

  .logout-modal {
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 400px;
      height: 300px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      transform: translate(-50%, -50%);

      .icon {
        width: 96px;
        height: 96px;
        color: #da2c38;
        margin-bottom: (@gutter * 0.75);
        transition: all 1.5s ease-in-out;
      }

    }
  }

}