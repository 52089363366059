@import "../../../../globals/styles/variables.less";

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #1f1f1f;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
  background-image: url('../../../../assets/images/marshall_loader.png');
  background-size: cover;
  background-position: center;
}

.video-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#avatarVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

/* Close button adjustments */
.close-button {
  position: absolute !important;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.7) !important;
  color: black !important;
  border-radius: 50%;
  z-index: 100;
}

.static-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  background: black;
  z-index: 1;
}

/* Loader styles */
.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../../assets/images/marshall_loader.png');
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.loader-overlay {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Chat input box at bottom */
.input-box {
  width: 100vw;
  position: fixed;
  bottom: 0;
}

@media (max-width: 600px) {
  .input-box {
    width: 100vw;
  }

  #avatarVideo {
    object-position: 40% center;
  }

  .loader-container {
    background-image: url('../../../../assets/images/marshall_loader.portrait.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .video-container {
    background-image: url('../../../../assets/images/marshall_loader.portrait.png');

  }
}


@media (min-width: 601px) {
  .input-box {
    width: 600px;
  }
}