@import "../../globals/styles/variables.less";

.update {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(#e8e4d8, 0.2);
    background-image: url("../../assets/images/mg-thumbprint.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    .maincontainer {
        position: relative;
        width: 500px;
        height: auto;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: @gutter;
        border-radius: 8px;
        background-color: white;

        .logoImage {
            width: 100px;
        }

        .maintitle {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .form-field {
            border-radius: 4px;
            background-color: white;
            margin-bottom: @gutter * 0.75;
            padding-bottom: (@gutter * 0.5);
        }

        .button-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 16px;

            .button-span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: (@gutter * 0.5);

                .MuiButton-root {
                    width: 180px;
                    margin: 8px;
                }
            }
        }

        .status {
            margin-top: 20px;
            // font-weight: bold;
            display: flex;
            flex-direction: column;
            align-items: center;

            &.success {
                color: green;
            }

            &.error {
                color: red;
            }
        }
    }

    .logo-container {
        position: absolute;
        bottom: 10px;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .powered-by-text {
        font-size: 9px;
        color: black;
    }

    .logo-image {
        width: 110px;
        height: 45px;
        object-fit: contain;
    }
}

@media screen and (max-width: 480px) {
    .update {
        .maincontainer {
            width: 350px;
            padding: 18px;
        }
    }
}