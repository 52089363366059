@import "../../globals/styles/variables.less";

.register {
  height: 100%;
  display: flex;
  background-color: rgba(#e8e4d8, 0.2);
  // background-image: url("../../assets/images/mg-thumbprint.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .mg-left-container {
    .mg-left-image {
      height: 100%;
    }
  }

  .login-right-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .inside-container {
      border: 1px solid #236B48;
      border-radius: 16px;
      // min-height: 504px;
      min-width: 504px;

      .card-first-part {
        background: #F7F7F7;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        min-height: 82px;
        display: flex;
        justify-content: center;
        align-items: center;

        .vertical-line {
          border: 0.9px;
          width: 18px;
          height: 62px;
          border-right: 1px solid #000;
          /* background: red; */
          margin-right: 10px;
          margin-left: 10px;
          opacity: 10%;
        }

        .logo-image {
          width: 110px;
          height: 45px;
          object-fit: contain;
        }
      }

      .card-second-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        padding-right: 12px;
        padding-left: 12px;

        .sign-in-line {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 16px 0px 16px 0px;
          gap: 12px;

          .sign-in-text {
            opacity: 70%;
            color: #000;
            font-size: 16px;
            font-weight: 600;
          }

          .horizontal-line {
            border: 1px solid rgba(73, 78, 88, 0.2);
            width: 72px;

          }
        }

        .form-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .form {
            display: flex;
            flex-direction: column;
            width: 80%;

            .form-fields-container {
              display: flex;
              gap: 16px;
            }

            .form-field {
              width: 100%;
              border-radius: 4px;
              background-color: white;
              margin-bottom: (@gutter * 0.35);

              .label-text {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                opacity: 0.7;

              }

              .button {
                border-radius: 4px;
                // background-color: var(--green, #236B48);
                box-shadow: 0px 2.56825px 0px 0px rgba(0, 0, 0, 0.04);
                color: #fff;
              }

              // &.button-field {
              //   margin-top: @gutter;
              // }


            }

          }
        }

        .have-an-account {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          margin-bottom: 48px;
          margin-top: 12px;
          color: #236B48;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    margin-top: 10px;
    background-color: white;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .terms {
      color: #595959;
      text-transform: none;
      padding-right: 10px;
      font-size: 10px;

      &:hover {
        color: #551A8B;
      }
    }

    .faText {
      color: #595959;
      text-transform: none;
      padding-left: 10px;
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 992px) {
  .register {
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .mg-left-container {
      display: none;
    }

    .containers {
      height: auto;
      min-height: 520px;
      border-top: 4px solid #00490a;

      .hero-image-container {
        display: none;
      }

      .form-container {
        .image {
          display: block;
          position: absolute;
          top: -60px;
          left: 50%;
          transform: translateX(-50%);

          img {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            border: 4px solid #00490a;
          }
        }

        .sign {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 38px;
          padding-top: 36px;
        }

        .logo {
          margin-top: (@gutter * 2);
        }
      }
    }

  }
}

@media screen and (max-width: 480px) {
  .register {
    .mg-left-container {
      display: none;
    }

    .containers {
      .form-container {
        .form {
          .form-fields-container {
            flex-flow: column;
            gap: 0;
          }
        }
      }
    }
  }
}