@import "../../styles/variables.less";

.error-page {
  width: 768px;
  height: 1024px;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .message {
      margin-bottom: @gutter;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button {
        width: 240px;
        font-size: 18px;
        font-weight: bold;
        border-radius: 12px;
        text-transform: capitalize;
        padding: @gutter;
        &:first-child {
          margin-right: @gutter;
        }
      }
    }
  }

}