// @import "../../../globals/styles/variables.less";

.footer {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  color: #505050;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  width: 100%; /* Ensure it takes full width */
}

.footer .privacy-policy-container {
  display: flex;
  gap: 12px;
  font-weight: 500;
  cursor: pointer;
}

.footer .vertical-line {
  border-right: 1px solid #969696;
  height: 100%;
}
