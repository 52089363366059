@import "../../globals/styles/variables.less";

.q-and-a-feedback {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: (@gutter * 2);

  .options {
    width: 80%;
    max-width: 1220px;
    padding: @gutter;
    border-radius: 12px;
    margin-bottom: @gutter;
    margin-top: (@gutter * 5);
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);

    .header {
      font-size: 24px;
      margin-bottom: (@gutter * 0.5);
    }

    .radio-group {
      .radio {
        // flex: 1;
        margin-right: (@gutter * 0.5);

        span.MuiRadio-root {
          padding: (@gutter * 0.33);
        }

        span.MuiTypography-root {
          line-height: 1.3em;
          font-size: 16px;
          word-break: keep-all;
        }
      }

      @media screen and (max-width: 768px) {
        flex-flow: column;
      }
    }
  }

  .form {
    width: 80%;
    max-width: 1220px;
    height: 650px;
    border-radius: 12px;
    margin-bottom: @gutter;
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);

    .form-field {
      width: 100%;
      padding: @gutter;

      .header {
        font-size: 24px;
        font-weight: medium;
        margin-bottom: (@gutter * 0.5);
      }

      .question {
        margin-bottom: (@gutter * 0.5);

        .question-text {
          font-size: 24px;
          font-weight: medium;
          margin-bottom: (@gutter * 0.25);
        }

        .text-button {
          padding: 0;
        }
      }

      .content {
        padding-right: (@gutter * 0.25);
        max-height: 220px;
        overflow: auto;
      }

      .answer-text-field,
      .source-text-field {
        margin-bottom: (@gutter * 0.75);
      }

      .button-field {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        transform: translateY(20px);

        .message {
          display: flex;
          flex-flow: row;
          align-items: center;
        }
      }
    }

  }

  .feedback-message {
    width: 80%;
    display: flex;
    flex-flow: column;
    max-width: 720px;
    padding: @gutter;
    border-radius: 12px;
    margin-bottom: @gutter;
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);

    div[role="alert"] {
      margin-bottom: (@gutter * 0.75);
    }

    .button {
      align-self: flex-end;
    }
  }

  .powered-by {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 18px;
    justify-content: flex-end;

    .text {
      font-size: 10px;
      color: @secondaryTextColor;
      margin-right: (@gutter * 0.25);
    }

    img {
      width: 60px;
    }
  }

}