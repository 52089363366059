.contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background-color: rgba(232, 228, 216, 0.2);
}

.form {
    width: 100%;
    max-width: 600px;
    background-color: #F7F7F7;
    padding: 20px;
    border: 1px solid #236B48;
    border-radius: 16px;
}

.label-text {
    margin-bottom: 8px;
    font-weight: 500;
    color: #000;
}

.form-field {
    margin-bottom: 16px;
}
