@import "../../globals/styles/variables.less";

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(.8);
  }
}

.chat-v2-screen {
  height: calc(100vh - 30px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;


  .big-container {
    display: flex;
    height: calc(100vh - 70px);
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .chat-history {
      width: 25%;
      height: 85%;
      margin-top: 85px;
      margin-right: 15px;
      background-color: white;
      border-radius: 16px;

      .chat-history-header {
        padding-left: 36px;
      }

      .chat-and-search-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;

        .search-button {
          border: 1px solid #837556;
          border-radius: 4px;
          padding: 11px 16px;
        }
      }

      .new-chat-button {
        border: 2px solid #00490a;
        border-radius: 10px;
        background-color: white;
        color: #236B48;
        font-size: 16px;
        text-transform: none;
        width: 80%;
        font-size: medium;

        &[disabled] {
          opacity: 0.4;
        }
      }

      .scrollable-list {
        padding-top: 10px;
        max-height: 75%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 14px;

        .user-session-chat {
          border-bottom: 1px solid #d8d8d8;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .user-sessions-loading {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .user-session-chat:hover {
          border: 1px solid #236B48;
          border-radius: 7px;
          background-color: #ECF9F2;
        }

        .active-user-session {
          border: 1px solid #236B48;
          border-radius: 7px;
          background-color: #ECF9F2;
        }

        .new-session-chip {
          padding: 6px;
        }

        .new-chat-text {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 12px;
          padding-left: 14px;
        }

        .session-display {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          width: 200px;
          word-wrap: break-word;
          white-space: normal;
        }

        .session-functions {
          display: flex;
          align-items: center;
        }

        .session-icons {
          font-size: 16px;
        }

      }

      .no-chats-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;

        .no-chats {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 14px;
          gap: 16px;

          div {
            opacity: 0.4;
          }

        }
      }
    }

    .container {
      width: 80%;
      height: 85%;
      max-width: 992px;
      margin-top: 85px;
      background-color: white;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.video-player {
        background-color: #212529;

        .input {
          background-color: #212529;

          .text-field {
            .input-field {
              box-shadow: none;
            }

          }

          .buttons {
            .icon-button {
              color: white;


            }


          }

        }


        .powered-by {
          .text {
            color: white;
          }
        }
      }

      .message-box {
        height: calc(100% - 110px);
        position: relative;
        background-color: white;
        // padding-bottom: (@gutter * 1.25);
        padding-bottom: 0px;
        border-radius: 16px;

        .message-placeholder {
          height: 100%;

          // position: relative;
          .suggestion-container {
            height: 160px;
            background-color: #F2F2F2;
            border-radius: 8px;
            margin: 36px 16px 0px 16px;

            .suggestion-header {
              font-size: 14px;
              padding: 16px;
              display: flex;
              justify-content: space-between;

              .i-icon {
                cursor: pointer;
              }
            }

            .cards-container {
              display: flex;
              gap: 12px;
              padding-right: 12px;
              padding-left: 12px;

              .arrow-icon {
                display: flex;
                justify-content: end;
                opacity: 0;
              }

              .card {
                background-color: #fff;
                height: 88px;
                width: 33%;
                font-size: 12px;
                padding: 8px;
                color: #000000;
                border-radius: 8px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .text {
                  opacity: 0.54;
                }
              }

              & :hover {
                background-color: #FEEECD;

                .arrow-icon {
                  opacity: 1;
                }
              }


            }
          }

          .desktop-view-placeholder {
            display: block;

            .tiles-container {
              display: flex;
              position: absolute;
              top: 50%;
              transform: translateY(-60%);
            }
          }

          .mobile-view-placeholder {
            display: none;

            .grid {
              padding: (@gutter * 0.5) (@gutter * 0.5) 0;

              .row {
                display: flex;
                flex-flow: row;

                .item {
                  flex: 1;
                  display: flex;
                  flex-flow: column;
                  align-items: center;
                  justify-content: center;
                  border-radius: 12px;
                  margin: (@gutter * 0.25);
                  border: 1px solid @borderColor;

                  &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                    transition: background-color 0.2s ease-in;
                  }

                  &.clicked {
                    background-color: rgba(0, 0, 0, 0.1);
                    transition: background-color 0.2s ease-in;
                  }
                }
              }
            }

            .tile-content {
              padding: @gutter;
            }
          }

          .tiles {
            .tile {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f2f2f2;
              padding: 16px;
              border-radius: 8px;
              margin-bottom: 10px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              font-size: 12px;
              height: 50px;
              overflow: hidden;
              text-overflow: ellipsis;

              &.special {
                transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;

                &:hover {
                  color: black;
                  background-color: rgba(0, 73, 10, 0.4);
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
                }
              }

              &.clickable {
                cursor: pointer;
              }
            }
          }


          .talks {
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 8px;
            left: 0;
            right: 0;
            padding: 0 (@gutter * 1.0);

            .box-container {
              display: flex;
              flex-direction: row;
              gap: 42px;

              .boxes {
                width: 155px;
                height: 50px;
                font-size: 12px;
                color: rgba(black, 0.7);
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                text-align: center;
                justify-content: center;
                border: 1px solid gray;
                border-radius: 8px;
              }
            }

            .title {
              margin-bottom: 10px;
            }
          }
        }

        .messages {
          height: 100%;
          overflow: auto;
          border-top-right-radius: 16px;
          border-top-left-radius: 16px;

          .loading-messages {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 24px;
          }

          .message {
            padding: (@gutter * 0.75);

            .top {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              margin-bottom: (@gutter * 0.25);

              .image {
                margin-right: (@gutter * 0.5);


                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  border: 1px solid @borderColor;

                }
              }

              .image-user {
                margin-left: (@gutter * 0.5);


                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  border: 1px solid @borderColor;

                }
              }

              .text-t {
                padding-bottom: 15px;
              }

              .answer-block {
                background-color: #E6E3DD;
                padding: 10px;
                border-radius: 5px;
                color: #236B48;
                padding-right: (@gutter * 0.5);
                width: 100%;
                border-radius: 5px;
                display: inline-block;
                overflow-wrap: break-word;
              }

              .user-text {
                background-color: #236B48;
                padding: 10px;
                border-radius: 5px;
                color: white;
                padding-right: (@gutter * 0.5);
                width: fit-content;
                border-radius: 5px;
                display: inline-block;
                overflow-wrap: break-word;
              }

              .message-delivery-button {
                svg {
                  width: 20px;
                  height: 20px;
                }
              }

            }

            .top-user {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              margin-bottom: (@gutter * 0.25);

              .image {
                margin-right: (@gutter * 0.5);


                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  border: 1px solid @borderColor;

                }
              }

              .image-user {
                margin-left: (@gutter * 0.5);


                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  border: 1px solid @borderColor;

                }
              }

              .user-text {
                background-color: #00490A;
                padding: 10px;
                border-radius: 5px;
                color: white;
                padding-right: (@gutter * 0.5);
                width: fit-content;
                border-radius: 5px;
                display: inline-block;
                overflow-wrap: break-word;
              }

              .message-delivery-button {
                margin-right: auto;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }

            }

            .actions {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              // padding-left: 56px;
              background-color: #F6F5F1;
              // padding: 10px;
              border-radius: 5px;
              color: #595959;
              // padding-right: (@gutter * 0.5);
              border-radius: 5px;
              margin-left: 1px;

              .button {
                width: 44px;
                height: 44px;
              }
            }

            .actions-panel {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            .mirrored-icon {
              transform: scaleX(-1);
            }

            .share-dialog {
              width: 250px;
              border-radius: 50px;
              height: 200px;
            }

            &.sent {
              background-color: white;

              .image {
                img {
                  padding: 4px;
                }
              }
            }

            &.received {
              background-color: white;
            }
          }
        }

        .waiting-message {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: -20px;
          left: 0px;
          right: 0px;
          z-index: 2;
          padding-left: @gutter;
          background-color: white;

          .lds-ellipsis {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 20px;

            div {
              position: absolute;
              top: 4px;
              width: 13px;
              height: 13px;
              border-radius: 50%;
              animation-timing-function: cubic-bezier(0, 1, 1, 0);

              &:nth-child(1) {
                left: 8px;
                background: #70c1b3;
                animation: lds-ellipsis1 0.6s infinite;
              }

              &:nth-child(2) {
                left: 8px;
                background: #ff1654;
                animation: lds-ellipsis2 0.6s infinite;
              }

              &:nth-child(3) {
                left: 32px;
                background: #f2cd49;
                animation: lds-ellipsis2 0.6s infinite;
              }

              &:nth-child(4) {
                left: 56px;
                background: #1789fc;
                animation: lds-ellipsis3 0.6s infinite;
              }
            }
          }

          .text {
            font-size: 14px;
            color: @secondaryTextColor;
          }
        }

        .hidden-audio {
          visibility: hidden;
        }

        &.hidden {
          display: none;
        }

      }

      .video-player-box {
        height: calc(100% - 70px);
        position: relative;
        background-color: #212529;

        &.hidden {
          display: none;
        }
      }

      .waiting-message-video {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 2;
        padding-left: @gutter;
        background-color: #212529;

        .lds-ellipsis-video {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 20px;

          div {
            position: absolute;
            top: 4px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);

            &:nth-child(1) {
              left: 8px;
              background: #70c1b3;
              animation: lds-ellipsis1 0.6s infinite;
            }

            &:nth-child(2) {
              left: 8px;
              background: #ff1654;
              animation: lds-ellipsis2 0.6s infinite;
            }

            &:nth-child(3) {
              left: 32px;
              background: #f2cd49;
              animation: lds-ellipsis2 0.6s infinite;
            }

            &:nth-child(4) {
              left: 56px;
              background: #1789fc;
              animation: lds-ellipsis3 0.6s infinite;
            }
          }
        }

        .text-video {
          font-size: 14px;
          color: white
        }
      }

      .input {
        height: 110px;
        background-color: #fcfcfc;
        padding: (@gutter * 0.75);
        border-radius: 16px;
        position: relative;

        .recording-container {
          border: 1px solid #acacac;
          height: 121px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          padding: 16px;
          position: absolute;
          bottom: 8px;
          left: 20px;
          right: 20px;

          .first-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;

            .timer {
              border: 1px solid rgba(35, 107, 72, 0.2);
              height: 59px;
              width: 59px;
              border-radius: 50%;
              display: flex;
              justify-content: space-around;
              align-items: center;

              .second-cirlce {
                border: 1px solid rgba(35, 107, 72, 0.4);
                ;
                height: 49px;
                width: 49px;
                border-radius: 50%;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .first-circle {
                  border: 1px solid #236B48;
                  height: 39px;
                  width: 39px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;

                  .time {
                    background-color: #236B48;
                    height: 29px;
                    width: 29px;
                    border-radius: 50%;
                    font-size: 10px;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                  }
                }
              }
            }

            .mg-voice-visualizer {
              width: 68%;

              .canvas-container {
                width: 100%;
              }
            }

            .audio-wave {
              width: 70%;
              border: 1px solid red;
              height: 60px;
            }

            .stop-recording {
              display: flex;
              align-items: center;
              background-color: #FDE9E7;
              border-radius: 60px;
              padding: 4px 6px 4px 12px;
              cursor: pointer;

              p {
                padding-right: 10px;
                color: #F06055;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }

          .second-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 5px;
            height: 44px;

            .loader-and-input {
              display: flex;
              align-items: center;

              .bouncing-loader {
                display: flex;
                justify-content: center;
                margin-right: 44px;

                >div {
                  width: 8px;
                  height: 8px;
                  margin: 3px 3px;
                  border-radius: 50%;
                  background-color: #a3a1a1;
                  opacity: 1;
                  animation: bouncing-loader 0.6s infinite alternate;

                  &:nth-child(2) {
                    animation-delay: 0.2s;
                  }

                  &:nth-child(3) {
                    animation-delay: 0.4s;
                  }
                }
              }
            }

            .silence-warning {
              font-size: 12px;
              font-weight: 500;
              opacity: 50%;
            }

            p {
              font-size: 14px;
            }
          }

        }

        .text-field {
          display: flex;
          align-items: center;
          margin-bottom: (@gutter * 0.5);

          .mic-button {
            background-color: #236B48;
            padding: 14px 16px;
            border-radius: 8px;
          }
          .video-button {
            background-color: #236B48;
            padding: 12px 14px;
            border-radius: 8px;
            color: white;
          }

          .input-field {
            border-radius: 8px;
            background-color: white;
            box-shadow: 0 1px 6px 1px #dedede;

            .MuiInputBase-root {
              padding: 8px;
            }

            textarea {
              padding-right: (@gutter * 0.75);

              &::-webkit-scrollbar {
                display: none;
              }
            }

            fieldset {
              border-radius: 8px;
            }

            &:hover {
              fieldset {
                border: 1px solid @primaryColor;
              }
            }
          }
        }

      }

      .input-for-recording {
        height: 146px;
      }

      .powered-by {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 18px;
        justify-content: flex-end;

        .text {
          font-size: 10px;
          color: @secondaryTextColor;
          margin-right: (@gutter * 0.25);
        }

        img {
          width: 60px;
        }
      }
    }
  }

  .footer {
    margin-top: 10px;
    background-color: white;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .terms {
      color: #595959;
      text-transform: none;
      padding-right: 30px;
      font-size: smaller;

      &:hover {
        color: #551A8B;
      }
    }

    .faText {
      color: #595959;
      text-transform: none;
      padding-left: 30px;
      font-size: smaller;

    }
  }

  .no-screen-support {
    display: none;
  }

  // audio {
  //   display: none;
  // }

  .audio-player {
    display: block;
    margin-left: 8px;

    &.hidden {
      display: none;
    }
  }
}

.info-pop {
  background-color: #F6F5F1;

  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 24px 40px 40px 40px;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #fff;
      height: 36px;
      font-weight: 600;
      margin-bottom: 20px;
      cursor: pointer;

      .common-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
      }

      .active {
        height: 36px;
        padding: 10px;
        background-color: #236B48;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        color: #fff;
      }

      .active2 {
        height: 36px;
        padding: 10px;
        background-color: #236B48;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        color: #fff;
      }

    }

    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      padding: 16px;
      border-radius: 8px;
      width: 400px;
      gap: 20px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #236B48;
        height: 32px;
        width: 32px;
        border-radius: 50%;
      }

      .text {
        font-size: 14px;
        width: 85%;
        opacity: 0.54;
        font-weight: 500;
        line-height: 1.5;
      }
    }

    .button {
      display: flex;
      width: 101px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: var(--green, #236B48);
      box-shadow: 0px 2.568px 0px 0px rgba(0, 0, 0, 0.04);
      color: #ffffff;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}

.dialog-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: @gutter;

  .icon-container {
    position: relative;
    width: 120px;
    height: 120px;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      font-size: 96px;
      color: rgba(#00490a, 0.35);
      transform: translateX(-50%) translateY(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: rgba(#e8e4d8, 0.35);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
    }
  }

  .text {
    margin-bottom: @gutter;
  }
}

@media screen and (max-width: 992px) {
  .chat-v2-screen {
    .big-container {
      .chat-history {
        .new-chat-button {
          font-size: small;
        }

        .scrollable-list {
          .session-display {
            padding: 6px;
            width: 120px;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
          }
        }
      }

      .container {
        .message-box {
          .message-placeholder {
            .suggestion-container {
              height: 160px;

              .cards-container {
                .card {
                  height: 88px;
                  width: 33%;
                  font-size: 10px;
                  padding: 6px;
                }

                & :hover {
                  background-color: #FEEECD;

                  .arrow-icon {
                    opacity: 1;
                  }
                }


              }
            }

            .desktop-view-placeholder {
              display: none;
            }

            .mobile-view-placeholder {
              display: block;
            }
          }
        }

        .input-field {
          font-size: 14px;

          textarea {
            padding-right: (@gutter * 0.75);
          }

          fieldset {
            border-radius: 20px;
          }

        }

      }
    }

    .footer {
      margin-top: 10px;
      background-color: white;
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .terms {
        color: #595959;
        text-transform: none;
        padding-right: 10px;
        font-size: 10px;


        &:hover {
          color: #551A8B;
        }
      }

      .faText {
        color: #595959;
        text-transform: none;
        padding-left: 10px;
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .chat-v2-screen {
    .big-container {
      .chat-history {
        // width: 25%;
        // height: 85%;
        // margin-top: 0;
        // padding-top: 20px;
        // margin-right: 8px;

        // .chat-history-header {
        //   padding-left: 15px;
        // }

        // .new-chat-button {
        //   font-size: smaller;
        // }

        // .scrollable-list {
        //   max-height: 70%;
        //   padding: 12px;
        // }
        display: none;
      }

      .container {
        width: 90% !important;
        height: 90% !important;
        margin-top: 30px !important;
        padding-top: 20px;

        .message-box {
          .message-placeholder {
            .suggestion-container {
              height: 316px;

              .cards-container {
                gap: 12.5px;
                padding-right: 12px;
                padding-left: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .card {
                  height: 70px;
                  width: auto;
                  font-size: 13px;
                  padding: 2px;
                }

                & :hover {
                  background-color: #FEEECD;

                  .arrow-icon {
                    opacity: 1;
                  }
                }


              }
            }
          }

          .waiting-message {
            .text {
              p {
                font-size: 14px !important;
              }
            }
          }

          .messages {
            .message {
              .top {
                .message-delivery-button {
                  svg {
                    width: 18px;
                    height: 18px;
                  }
                }
              }

              .actions-panel {
                display: flex;
                flex-direction: column !important;
              }

              .share-dialog {
                height: 500px;
                width: 100px;
              }
            }
          }
        }

        .input {
          .recording-container {
            height: 100px !important;
            padding: 12px !important;

            .first-container {
              gap: 12px !important;
            }
          }

          .input-field {

            textarea {
              font-size: 14px;

              &:placeholder {
                font-size: 14px;
              }
            }

          }

          .restart-conversation {
            width: 48px;
            height: 48px;
            min-width: 48px;
          }
        }

      }
    }

    .footer {
      margin-top: 30px !important;
      background-color: white;
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .terms {
        color: #595959;
        text-transform: none;
        padding-right: 10px;
        font-size: 10px;

        &:hover {
          color: #551A8B;
        }
      }

      .faText {
        color: #595959;
        text-transform: none;
        padding-left: 10px;
        font-size: 10px;

      }
    }
  }

  .audio-player {
    margin-left: 0px !important;
    padding: 4px;
    width: 200px;
  }
}

@media only screen and (orientation: landscape) and (max-height: 479px) {
  .chat-v2-screen {
    .container {
      display: none;
    }

    .no-screen-support {
      max-width: 80%;
      margin: auto;
      display: block !important;
      padding: @gutter;
    }
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-12px);
  }
}

/*Info-pop card width for different mobile screens*/
@media screen and (max-width: 399px) {
  .info-pop {
    .inner-container {
      .card {
        width: 270px !important;
      }
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 480px) {
  .info-pop {
    .inner-container {
      .card {
        width: 320px !important;
      }
    }
  }
}
