@import "../../globals/styles/variables.less";

.check {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(#e8e4d8, 0.2);
    background-image: url("../../assets/images/mg-thumbprint.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    .maincontainer {
        width: max-content;
        max-width: 420px;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        padding: @gutter;
        border-radius: 8px;
        background-color: white;

        .logoImageContainer {
            width: 100%;
            margin-bottom: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .maintitle {
            font-size: 1.25rem;
        }

        .container {
            display: flex;
            align-items: flex-start;
            margin-bottom: 5px;
        }

        .form-field {
            width: 100%;
            border-radius: 4px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            margin-right: 6px;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // margin-left: 6px;
            transform: translateX(15px);
        }

        .icon-span {
            display: flex;
            align-items: center;
            cursor: pointer;

            &.clicked {
                transform: scale(0.95);
                transition: transform 0.2s ease-in-out;
            }
        }

        .large-button {
            font-size: 72px;
            // transform: translateY(-15px);
        }

        .status {
            margin-top: 10px;
            // font-weight: bold;
            display: flex;
            flex-direction: column;
            align-items: center;

            &.success {
                color: green;
            }

            &.error {
                color: red;
            }
        }
    }

    .logo-container {
        position: absolute;
        bottom: 10px;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .powered-by-text {
        font-size: 9px;
        color: black;
    }

    .logo-image {
        width: 110px;
        height: 45px;
        object-fit: contain;
    }
}

@media screen and (max-width: 480px) {
    .check {
        .maincontainer {
            max-width: 350px;
            padding: 18px;
        }
    }
}