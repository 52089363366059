@import "../../globals/styles/variables.less";
 
.layout {
  height: 100%;
  overflow: auto;
  position: relative;
 
  .page-header {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    box-shadow: 0px 1px 3px 1px#cecece;
    padding-right: 30px;
    // padding-right: 40px;
  }
  .alert {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    margin-top: 60px;
    align-items: center;
    justify-content: center;
    padding: (@gutter * 0.25);
    &.offline {
      background-color: #f2f2f2;
    }
    &.online {
      background-color: #CAF3DB;
    }
    span {
      margin-left: (@gutter * 0.25);
    }
  }
  .bottom-navigation-icon {
    position: fixed;
    bottom: 0;
    left: 0;  
    z-index: 2;
    height: 40px;
    width: 100%;
    display:flex;
    margin-top: 24px;
    margin-right: 50px;
    justify-content: center;
    background-color: transparent;
  }
//   .menu{
//     padding-top: 8%;
//     padding-right: 20%;
  
//   .icon-button{
//     padding-right: 10%;
   
//   }
// }
// .button {
//   text-transform: none;
//   padding-left: 16px;
//   padding-right: 16px;
// }
// }
span.MuiBottomNavigationAction-label {
  font-size: 1.15rem!important;
}
.icon-text {
  font-size: 14px;
  margin-left: 4px;
}

.icon-button {
  display: flex;
  align-items: center;
}

.icon-button svg {
  margin-right: 4px;
  font-size: 22px;
}

// @media screen and (max-width: 480px) {
//   .layout {
//     height: 100%;
//     overflow: auto;
//     position: relative;
//     .page-header {
//       position: fixed;
//       top: 0;
//       z-index: 2;
//       width: 100%;
//       margin-right: 0px !important;
//       box-shadow: 0px 1px 3px 1px#cecece;
//     }}
 
 
 
//   .menu-item{
//     padding-right: 0%;
//   }
//   .menu {
//     padding-right: 0%!important;
//     padding-left: 0px;
//     padding-top: 10px!important;

//   .history{
//     padding-top: 7%;
//     padding-left: 0px;
//     padding-right: 3px;
//   }
 
// }
// .icon-button{
//   padding-right: 5%!important;
}
// }
// }
// .chat-history {
//   width: 90%;
//   height: 85%;
//   margin-top: 85px;
//   margin-right: 15px;
//   margin-left:15px;
//   background-color: white;
//   border-radius: 16px;
 
//   .chat-history-header {
//     padding-left: 36px;
//   }
// }

#language-menu {
  margin-right: 2px;
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 5px 10px;
  border-radius: 50px;
  border: 1px solid lightgray;
  min-width: 40px;
  height: 40px;
  justify-content: center;
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .icon-text {
    display: none;
  }}
.scrollbar-languages {
    max-height: 227px; /* Matches your PaperProps height */
    overflow-y: auto;
}

.scrollbar-languages::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
}

.scrollbar-languages::-webkit-scrollbar-thumb {
    background-color: rgb(35, 107, 72); /* Scrollbar color */
    border-radius: 10px;
}

.scrollbar-languages::-webkit-scrollbar-thumb:hover {
    background-color: rgb(35, 107, 72); /* Hover color */
    cursor: pointer;
}

.scrollbar-languages::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Track color */
}
