@import "../../globals/styles/variables.less";

.q-and-a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  .form {
    width: 80%;
    max-width: 1220px;
    border-radius: 12px;
    margin-bottom: @gutter;
    margin-top: (@gutter * 5);
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);

    .form-field {
      width: 100%;
      padding: (@gutter * 0.5) (@gutter * 0.75);

      &:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.20);
      }

      .question-text-field {
        >div {
          padding: (@gutter * 0.75);

          textarea {
            font-size: 24px;
          }

          textarea::placeholder {
            font-size: 28px;
            line-height: 1.2em;
          }

          fieldset {
            border: 0;
          }
        }
      }

      &.button-field {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;

        .message {
          display: flex;
          flex-flow: row;
          align-items: center;
        }
      }
    }
  }

  .answer-box {
    width: 80%;
    max-width: 1220px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.20);

    .answer {
      max-height: 300px;
      overflow: auto;
      font-size: 1.2rem;
      padding: (@gutter * 0.75);
      border-bottom: 1px solid rgba(0, 0, 0, 0.20);
      @media screen and (min-height: 992px) {
        max-height: 480px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #999;
      }

      &::-webkit-scrollbar-track {
        background: #ccc;
      }
    }

    .feedback {
      display: flex;
      align-items: center;
      padding: (@gutter * 0.5) (@gutter * 0.75);

      .ratings {
        flex: 1;

        .thumbs-up-button,
        .thumbs-down-button {
          margin-right: (@gutter * 0.33);

          &.selected {
            color: #5a80fb;
            transition: color ease-in 0.75s;
          }
        }
      }
    }

  }

  .failed-api-message {
    border-radius: 4px;
    background-color: #ef5350;
    border: 1px solid rgb(255, 210, 210);
    padding: (@gutter * 0.25) (@gutter * 0.5);

    >div {
      >div {
        display: flex;
        flex-flow: row;
        align-items: center;
        color: white;

        .icon {
          margin-right: (@gutter * 0.5);
        }
      }
    }
  }

  .powered-by {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 18px;
    justify-content: flex-end;

    .text {
      font-size: 10px;
      color: @secondaryTextColor;
      margin-right: (@gutter * 0.25);
    }

    img {
      width: 60px;
    }
  }

}