@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins:wght@100;300;400;500;700&display=swap');

.landing-page-main {
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    background-color: #FFFFFFE5;

    .landing-page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background: #e6e6e6;

        .mg-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;

            img {
                width: 280px;
            }
        }

        .right-pane {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-right: 10px;
            position: relative;

            .lets-chat-button {
                background-color: #0987C5;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                border-radius: 50px;
                padding: 10px 16px;
                width: 250px;
                cursor: pointer;
                font-weight: 700;
            }

            .powered-by-logo {
                display: flex;
                align-self: flex-end;
                position: absolute;
                bottom: -25px;
                right: 5px;
            }
        }
    }

    .landing-page-second-part {
        overflow-y: scroll;
        overflow-x: hidden;

        .landing-page-transition-part {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100vh - 84px);
            width: 100%;
            background-image: url('../../assets/images/landingPageBG.png');
            background-size: cover;

            .marshall-text {
                margin-top: 20px;
                font-size: 42px;
                font-weight: 500;
                text-align: center;
                color: #fff;
                margin-top: 32px;
            }

            .marshall-text2 {
                font-size: 80px;
                font-weight: 700;
                text-align: center;
                background: linear-gradient(178.71deg, #FFFFFF 25.84%, rgba(255, 255, 255, 0) 98.9%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                line-height: 80px;
            }

            .marshall-image {
                display: flex;
                justify-content: center;
                height: 70%;
                transform: translate(50px, 0px);
            }

            .scroll-down-arrow {
                position: absolute;
                bottom: 16px;
                left: 50%;
                //dont keep tranform here, as it is creating overflow
            }

        }

        .landing-page-text-part {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            background: #053A2A;
            padding: 40px 38px 50px 38px;
            font-weight: 300;

            .mg-image-container {
                width: 750px;
                height: 435px;
                margin-left: 25px;
                margin-right: 25px;
                margin-top: 6px;

                .mg-chair-image {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }

            .text-and-button {
                display: flex;
                flex-direction: column;
                margin-right: 25px;
                height: 450px;
                margin-left: 25px;
            }

            .text-content {
                text-align: left;
                font-size: 22px;
                line-height: 28px;
                font-weight: 300;
            }

            .lets-chat-button {
                background-color: #0987C5;
                display: flex;
                justify-content: center;
                align-items: left;
                color: #fff;
                border-radius: 50px;
                padding: 10px 10px;
                width: 175px;
                cursor: pointer;
                font-weight: 700;
                z-index: 10;
                margin-top: 20px;
                height: 40px;
            }
        }

        .points {
            display: flex;
            justify-content: space-evenly;
            margin: 10px;
            padding-top: 20px;
        }

        .points-box {
            width: 400px;
            height: 68px;
            background-color: white;
            background-color: white;
            border: 1px solid #053A2A;
            border-radius: 10px;
        }

        .box-contents {
            display: flex;
            flex-direction: row;
            height: 68px;
            justify-content: center;
            align-items: center;
            padding: 8px;
        }

        .image-container {
            width: 14%;
            height: 100%;
            border: 1px solid #053A2A;
            border-radius: 10px;
            margin-right: 12px;
            margin-left: 5px;
            overflow: hidden;
            background-color: #053A2A;
        }

        .icons {
            width: 100%;
            height: 100%;
            border: 1px solid #053A2A;
            fill: white;
            padding: 8px;
        }

        .right-text {
            flex: 1;
            margin-right: 5px;
            font-size: 16px;
            color: #595959;
        }

        .three-points {
            display: flex;
            padding: 50px;
            gap: 64px;

            .first-point {
                display: flex;
                gap: 16px;
                font-weight: 500;
                color: #161616;
                font-size: 19.5px;
                line-height: 35px;

                white-space: nowrap;

                // padding-right: 20px;
                .tick-mark {
                    height: 32px;
                    width: 32px;
                    margin-top: 4px;
                }
            }

            .second-point {
                display: flex;
                // align-items: center;
                gap: 16px;
                font-weight: 500;
                color: #161616;
                font-size: 19.5px;
                line-height: 35px;
                justify-content: center;
                white-space: wrap;

                .tick-mark {
                    height: 32px;
                    width: 32px;
                    margin-top: 4px;
                }
            }

            .third-point {
                display: flex;
                // align-items: center;
                gap: 16px;
                font-weight: 500;
                color: #161616;
                font-size: 19.5px;
                line-height: 35px;

                .tick-mark {
                    height: 32px;
                    width: 32px;
                    margin-top: 4px;
                }
            }

        }

        // .examples-header{
        //     font-weight: 500;
        //     font-size: 27px;
        //     line-height: 40px;
        //     color: #00490A;
        //     text-align: center;
        //     margin-top: 5px;
        //     margin-bottom: 20px;
        // }

        .threeQuestionsBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: calc(100% - 80px);
            height: auto;
            max-height: 1162px;
            /* Maximum height */
            overflow-y: auto;
            gap: 0px;
            opacity: 1;
            background: #f2f2f2;

            .free-three-questions-container {
                display: flex;
                justify-content: center;


                .free-three-questions {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 36px;
                    gap: 0px;
                    opacity: 1;
                    font-family: Araboto-Medium;
                    font-size: 32px;
                    font-weight: 500;
                    //    line-height: 1.5;
                    text-align: center;
                    color: #00490A;
                    background-color: #f2f2f2;
                    margin-top: 30px;
                }

                .three_questions_arrow {
                    display: flex;
                    align-items: center;
                    width: 43.58px;
                    height: 50.84px;
                    margin-top: 50px;
                    margin-left: 10px;
                    padding: 0px;
                    gap: 0px;
                    opacity: 1;
                }
            }

            .chat-v2-screen {
                height: calc(100vh - 80px);
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }

            .chat-v2-screen .big-container {
                display: flex;
                height: calc(100vh - 80px);
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-top: 0px !important;
            }

            .chat-v2-screen .big-container .container {
                width: 80%;
                height: 88%;
                max-width: 992px;
                margin-top: 0px !important;
                background-color: white;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .signInNotes {
                background: #EBF9F2;
                border: 2px solid #00490A;
                width: calc(100% - 220px);
                height: auto;
                margin: 10px auto;
                gap: 0px;
                margin-top: 1px;
                padding-top: 0px;
                margin-bottom: 30px;
                position: relative;
                /* Added for positioning */
                padding: 20px;
                border-radius: 10px;
                border: 2px;
                opacity: 1;

                .signInNotesInnerBox {
                    width: auto;
                    height: auto;
                    position: relative;
                    /* Added for positioning */
                    // padding: 10px; 
                    margin-bottom: 10px;
                    border-radius: 50px;
                    opacity: 1;
                    display: flex;
                    justify-content: center;

                    button {
                        background: #0987C5;
                        color: white;
                        border: none;
                        padding: 10px 20px;
                        border-radius: 5px;
                        margin-top: 10px;
                        cursor: pointer;
                        align-content: center;
                    }

                }


                .signin-points {
                    display: flex;
                    padding: 20px;
                    gap: 64px;

                    .first-point {

                        display: flex;
                        gap: 16px;
                        font-weight: 500;
                        color: #161616;
                        font-size: 14.5px;
                        line-height: 30px;

                        white-space: nowrap;

                        // padding-right: 20px;
                        .tick-mark {
                            height: 28px;
                            width: 28px;
                            margin-top: 4px;
                        }
                    }

                    .second-point {
                        display: flex;
                        // align-items: center;
                        gap: 16px;
                        font-weight: 500;
                        color: #161616;
                        font-size: 14.5px;
                        line-height: 30px;
                        justify-content: center;
                        white-space: wrap;

                        .tick-mark {
                            height: 28px;
                            width: 28px;
                            margin-top: 4px;
                        }
                    }

                    .third-point {
                        display: flex;
                        // align-items: center;
                        gap: 16px;
                        font-weight: 500;
                        color: #161616;
                        font-size: 14.5px;
                        line-height: 30px;

                        .tick-mark {
                            height: 28px;
                            width: 28px;
                            margin-top: 4px;
                        }
                    }

                }
            }
        }


        .carousel-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            margin-top: 8px;
            margin-bottom: 10px;
            margin-left: 1px;
            margin-left: 1px;
            margin-right: 0px;
            cursor: pointer;
            transition: transform 0.3s ease;

            /* Style the chat cards */
            .chat-card1,
            .chat-card2,
            .chat-card3 {
                background-color: #F3F3F3;
                margin: 20px;
                padding: 20px 34px 34px 40px;
                /* Top, right, bottom, left */
                width: 500px;
                height: 455px;
                gap: 10px;
                border-radius: 8px;
                border: 1px solid #CDCDCD;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                max-width: 100%;
                /* Limit the width of chat cards */
                // overflow-y: scroll; /* Enable vertical scrolling */
                margin-bottom: 10px;
            }

            /* Style user messages */
            .user-message {
                // position:sticky;
                top: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }

            .user-message p {
                background-color: #00490A;
                color: white;
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 10px;
                max-width: 100%;
                overflow-wrap: break-word;
            }

            .bot-message {
                background-color: #f3f3f3;
                display: flex;
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 10px;
                max-width: 100%;
                overflow-wrap: break-word;
                // overflow-y: auto; /* Make the bot message scrollable */
                max-height: 200px;
                /* Set a maximum height for scrolling */

                .message-footer {
                    background-color: #E5E3DD;
                    // display:flex;
                    margin-bottom: 10px;
                    // padding: 10px;
                    border-radius: 10px;
                    max-width: 100%;
                    height: 440px;
                    overflow-wrap: break-word;
                    // overflow-y: auto; /* Make the bot message scrollable */
                    max-height: 265px;
                    /* Set a maximum height for scrolling */

                    .bot-message-content {
                        background-color: #E5E3DD;
                        display: flex;
                        margin-bottom: 1px;
                        padding: 10px;
                        border-radius: 10px;
                        max-width: 100%;
                        overflow-wrap: break-word;
                        overflow-y: auto;
                        /* Make the bot message scrollable */
                        max-height: 220px;
                        /* Set a maximum height for scrolling */

                    }

                    .like-count-container{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 12px;
                        margin-top: 0px;
                        gap: 3px;
                        background-color: white;
                        width: 80px;
                        padding: 6px;
                        border-radius: 15px;
                        text-align: end;
                        margin-left: auto;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    }

                    .chat-bot-footer {
                        // display:block;
                        justify-content: center;
                        /* Align buttons to the right */
                        bottom: 1px;
                        /* Align it to the bottom */
                        align-items: center;
                        padding: 10px, 40px, 5px, 1px;
                        /* Top, right, bottom, left */
                        margin: 10px;
                        // margin-bottom: 20px;
                        border-radius: 2px;
                        background-color: #E8E8E8;
                        border: 1px solid #ebeae8;
                    }

                    .button-container {
                        display: flex;
                        justify-content: flex-end;
                        padding-left: 20px !important;
                        border-radius: 2px;
                        gap: 10px;
                        /* Add spacing between symbols */
                        justify-content: flex-end;
                        background-color: #f3f3f3;

                        .thumbs_up,
                        .thumbs_down,
                        .copy {
                            width: 24px;
                            /* Adjust the image size as needed */
                            height: 24px;
                            color: #7f7d7d;
                        }

                    }

                }
            }

            .scrollable-bot-message-content {
                max-height: 240px !important;
            }

            /* Style profile images */
            .user-profile-img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #E5E3DD;
                align-self: flex-start;
                margin-top: 15px;
                margin-left: 10px;
                margin-block-end: 10px;

            }

            .bot-profile-img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #E5E3DD;
                align-self: flex-start;
                margin-top: 15px;
                margin-right: 10px;
                margin-block-end: 10px;

                .alice-carousel__stage-item {
                    //   margin-right: 10px; 
                    width: 100%;
                    gap: 10px;
                    line-height: initial;
                    background-color: #f0f0f0;
                    border: 1px solid #ddd;
                    //   padding: 20px;
                    text-align: center;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    visibility: visible;
                }
            }
        }

        .container-button {

            display: flex;

            justify-content: center;

            padding-top: 20px;

            button {

                margin-right: 10px;

                background-color: #ffffff;

                color: #000000;

                border: 1px solid #000000;

                padding: 10px 20px;

                cursor: pointer;

                font-size: 16px;

                // font-weight: bold;

                transition: background-color 0.3s, color 0.3s;

                border-radius: 30px;

            }

            button.active {

                background-color: #023602;
                /* Green when active */

                color: #ffffff;
                /* White text when active */

            }
        }

        .arrow {

            margin-left: 5px;

        }

        .carousel-container-youtube {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 1px;
            margin-left: 1px;
            margin-right: 0px;
            cursor: pointer;

            .thumbnail {
                width: 332px;
                height: 416px;
                gap: 40px !important;
                border-radius: 8px;
                max-width: 100%;

                .thumbnail:last-child {
                    margin-right: 10px !important;
                }

                img {
                    // display:flex;
                    // margin: 20px;
                    width: 90%;
                    height: 90%;
                    margin: 20px;
                    gap: 40px !important;
                    object-fit: cover;
                    transition: transform 0.03s ease;

                }

                &:hover {
                    transform: scale(1.03);
                }
            }
        }


        // /* Styling for carousel items */
        .carousel {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 10px;
            padding-right: 1px;
            width: 100%;
            height: auto;
            margin: 10px;
            cursor: pointer;
            transition: transform 0.3s ease;


            .alice-carousel {
                margin-right: 10px;
                margin-left: 10px;
                align-items: center;
                width: 100%;
                line-height: initial;

            }

            .alice-carousel__stage-item {
                width: auto;
                gap: 40px !important;
                line-height: initial;
            }
        }

        .name-and-text-container {
            background: linear-gradient(349.46deg, rgba(148, 220, 254, 0.56) -96.15%, rgba(255, 255, 255, 0.8) 82%);

        }

        .name-marshall {
            text-align: center;
            font-size: 100px;
            background: linear-gradient(180deg, rgba(62, 139, 209, 0.6) 0%, rgba(62, 139, 209, 0) 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            font-weight: 700;
            line-height: 114px;
            margin-top: 50px;
        }

        .marshall-image-container2 {
            display: flex;
            justify-content: center;
            position: relative;
            height: 664px;

            img {
                position: absolute;
                left: 50%;
                height: 700px;
                top: 264px;
                transform: translate(-50%, -50%);
            }
        }

        .marshall-desc {
            color: #161616;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            padding-right: 10%;
            padding-left: 10%;
            line-height: 30px;

        }

        .social-icons {
            text-align: center;
            margin-top: 20px;
            padding-bottom: 20px;
            display: flex;
            gap: 12px;
            justify-content: center;

            .each-icon {
                cursor: pointer;
            }
        }

        .gen-ai-solutions-container {
            background-image: url('../../assets/images/landingPageBG.png');
            background-size: 100% 100%;
            color: #fff;
            text-align: center;

            .gen-ai-header {
                font-size: 26px;
                font-weight: 420;
                padding: 55px 20px 40px 20px;

            }

            .gen-ai-three-points {
                display: flex;
                justify-content: center;
                gap: 65px;
                margin-right: 58px;
                margin-left: 31px;

                .each-point {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 19.5px;
                    font-weight: 400;
                    text-align: left;
                    gap: 20px;

                    // line-height: 40px;
                    .genai-icon {
                        height: 60px;
                        width: 60px;
                    }
                }
            }

            .gen-ai-text1 {
                font-size: 19px;
                margin-top: 90px;
                font-weight: 400;
            }

            .gen-ai-text2 {
                font-size: 28px;
                font-weight: 600;
                background: linear-gradient(180.94deg, #FFFFFF 22.67%, #3D8FA4 72.96%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }

            .gen-ai-text3 {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0px;
                margin-top: 12px;
            }

            .quote {
                margin-top: 72px;
            }

            .fractal-description {
                font-size: 19px;
                font-style: italic;
                font-weight: 400;
                letter-spacing: 0px;
                text-align: center;
                margin: 20px 150px 50px 150px;
                line-height: 34px;
            }

            .fractal-founders {
                display: flex;
                justify-content: center;
                gap: 40px;
                padding-bottom: 24px;

                .person {
                    .name {
                        padding-bottom: 10px;
                        font-size: 17px;
                        font-weight: 500;
                        text-align: center;
                        line-height: 24px;
                    }

                    .role {
                        color: #BEBEBE;
                        font-size: 12px;
                        margin-bottom: 8px;
                    }
                }

                .vertical-line {
                    border-right: 1px solid #969696;
                }
            }
        }

        .footer {
            display: flex;
            justify-content: space-between;
            background-color: #ffffff;
            color: #505050;
            padding: 12px;
            font-weight: 400;
            font-size: 14px;

            .privacy-policy-container {
                display: flex;
                gap: 12px;
                font-weight: 500;
                cursor: pointer;

                .vertical-line {
                    border-right: 1px solid #969696;
                }
            }
        }
    }


}

/* For Mobile Devices (from 400px to 767px) */
@media only screen and (min-width:400px) and (max-width: 767px) {

    // .landing-page-main .landing-page-second-part .landing-page-transition-part .marshall-image{
    //     overflow: unset;
    // }
    .landing-page-main .landing-page-header {
        padding: 20px 12px 20px 12px;
    }

    .landing-page-main .landing-page-header .mg-logo img {
        width: 190px
    }

    .landing-page-main .landing-page-second-part .landing-page-transition-part {
        background-image: url('../../assets/images/bg-img-mob.png');
    }

    .landing-page-main .landing-page-second-part .landing-page-transition-part .marshall-text {
        font-size: 30px;
    }

    .landing-page-main .landing-page-second-part .landing-page-transition-part .marshall-text2 {
        font-size: 52px;
        line-height: 60px;
        font-weight: 700;
    }

    .landing-page-main .landing-page-header .right-pane .lets-chat-button {
        width: 140px;
        height: 36px;
        font-weight: 500;
    }

    .landing-page-main .landing-page-second-part .landing-page-text-part {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .landing-page-main .landing-page-second-part .mg-image-container {
        height: 180px !important;
        width: 360px !important;
        margin-left: 6px !important;
        margin-right: 6px !important;
        margin-bottom: 6px;
    }

    .landing-page-main .landing-page-second-part .text-and-button {
        padding-top: 6px;
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .landing-page-main .landing-page-second-part .landing-page-text-part .text-content {
        font-size: 18px;
        line-height: 18px;
    }

    .landing-page-main .landing-page-second-part .lets-chat-button {
        height: 40px !important;
        width: 140px !important;
        transform: translateY(5px) !important;
    }

    .landing-page-main .landing-page-second-part .points {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 14px;
    }

    .landing-page-main .landing-page-second-part .points-box {
        width: 100%;
    }

    .landing-page-main .landing-page-second-part .right-text {
        font-size: 15px;
    }

    .landing-page-main .landing-page-second-part .three-points {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 24px;

        .first-point {
            align-items: center;
            font-size: 19px;
            font-weight: 500;
            line-height: 28px;

        }

        .each-point {
            font-size: 19px;
            font-weight: 500;
            line-height: 28px;
        }
    }

    .landing-page-main .landing-page-second-part .name-marshall {
        font-size: 64px;
        line-height: 64px;
    }

    .landing-page-main .landing-page-second-part .examples-header {
        font-size: 32px;
        font-weight: 500;
    }

    .threeQuestionsBox {
        display: flex !important;
        flex-direction: column;
        margin: auto;
        width: 100% !important;
        max-height: 3190px !important;
        height: auto !important;
        overflow-y: hidden !important;
        gap: 0px;
        opacity: 1;
        /* Make visible */
        background: #f2f2f2;
        border: 1px solid #D5D5D5 !important;
        padding: 0px;
        border-radius: 8px;
        margin-bottom: 28px !important;
        position: relative;

        .free-three-questions-container {
            display: flex;
            justify-content: center;
            margin-top: 35px !important;

            .free-three-questions {
                display: flex;
                font-family: Araboto-Medium;
                font-size: 28px !important;
                font-weight: 500 !important;
                line-height: 1.5;
                text-align: center;
                color: #00490A;
                background-color: #f2f2f2;
                padding: 10px 20px;
                border-radius: 8px;
                margin-top: 30px;
            }

            .three_questions_arrow {
                display: flex;
                align-items: center;
                width: 43.58px;
                height: 50.84px;
                margin-top: 70px !important;
                margin-right: 10px;
                padding: 0px;
                gap: 0px;
                opacity: 1;
            }
        }

        .chat-v2-screen {
            height: calc(100vh - 100px) !important;
            margin-top: 0px !important;
            display: flex !important;
            margin-bottom: 0px !important;
            align-items: center !important;
            flex-direction: column !important;
            justify-content: center !important;
        }

        .chat-v2-screen .big-container {
            display: flex;
            height: calc(100vh - 200px) !important;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 0px !important;
        }

        .chat-v2-screen .big-container .container {
            width: 90% !important;
            height: calc(100vh - 200px) !important;
            max-width: 992px;
            margin-top: 0px !important;
            background-color: white;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }


        .signInNotes {
            background: #EBF9F2;
            border: 2px solid #00490A !important;
            width: 90% !important;
            margin: 0px auto;
            gap: 0px;
            margin-top: 0px !important;
            margin-bottom: 30px !important;
            padding: 0px;
            border-radius: 8px;
            opacity: 1;
            /* Make visible */


            .signInNotesInnerBox {
                width: 100%;
                height: auto;
                position: relative;
                gap: 0px !important;
                margin-bottom: 10px;
                border-radius: 8px;
                opacity: 1;
                /* Make visible */
                display: flex;
                justify-content: center;

                button {
                    background: #0987C5;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    border-radius: 5px;
                    cursor: pointer;
                    align-content: center;
                }
            }

            .signin-points {
                display: flex;
                flex-direction: column;
                padding: 20px;
                margin-bottom: 5px !important;
                gap: 10px !important;
                /* Adjusted gap */
                opacity: 1;
                /* Make visible */

                .first-point .second-point,
                .third-point {
                    display: flex;
                    gap: 8px;
                    font-weight: 500;
                    color: #161616;
                    font-size: 14px;
                    line-height: 1.5;
                }

                .tick-mark {
                    height: 28px;
                    width: 28px;
                    margin-top: 4px;
                }
            }
        }
    }

    .carousel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 10px;
        cursor: pointer;
        transition: transform 0.3s ease;

        .chat-card1,
        .chat-card2,
        .chat-card3 {
            background-color: #F3F3F3;
            margin: 20px;
            padding: 10px 42px 14px 10px !important;
            /* Top, right, bottom, left */
            margin: 10px;
            width: 300px;
            height: 500px;
            border-radius: 8px;
            border: 1px solid #000000;
            /* Add a black border */
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            max-width: 100%;
            /* Limit the width of chat cards */
            // overflow-y: scroll; /* Enable vertical scrolling */
            margin-bottom: 10px;

            .user-message {
                // position:sticky;
                top: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }

            .user-message p {
                background-color: #00490A;
                color: white;
                /* Set text color to white */
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 10px;
                max-width: 100%;
                overflow-wrap: break-word;
            }

            .bot-message {
                background-color: #f3f3f3;
                display: flex;
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 10px;
                max-width: 100%;
                overflow-wrap: break-word;
                // overflow-y: auto; /* Make the bot message scrollable */
                max-height: 230px;
                /* Set a maximum height for scrolling */

                .message-footer {
                    background-color: #E5E3DD;
                    // display:flex;
                    margin-bottom: 10px;
                    // padding: 10px;
                    border-radius: 10px;
                    max-width: 100%;
                    height: 440px;
                    overflow-wrap: break-word;
                    // overflow-y: auto; /* Make the bot message scrollable */
                    max-height: 200px;

                    /* Set a maximum height for scrolling */
                    .bot-message-content {
                        background-color: #E5E3DD;
                        display: flex;
                        margin-bottom: 1px;
                        padding: 10px;
                        border-radius: 10px;
                        max-width: 100%;
                        overflow-wrap: break-word;
                        overflow-y: auto;
                        /* Make the bot message scrollable */
                        max-height: 180px;
                        /* Set a maximum height for scrolling */

                    }

                    .chat-bot-footer {
                        // display:block;
                        justify-content: center;
                        /* Align buttons to the right */
                        bottom: 1px;
                        /* Align it to the bottom */
                        align-items: center;
                        padding: 8px, 20px, 5px, 100px;
                        /* Top, right, bottom, left */
                        margin: 5px;
                        // margin-bottom: 20px;
                        background-color: #E5E3DD;
                        border: 1px solid #ebeae8;
                    }

                    .button-container {
                        display: flex;
                        justify-content: flex-end;
                        gap: 10px;
                        /* Add spacing between symbols */
                        justify-content: flex-end;
                        background-color: #f3f3f3;

                        .thumbs_up,
                        .thumbs_down .copy {
                            width: 24px;
                            /* Adjust the image size as needed */
                            height: 24px;
                        }

                    }
                }

                .scrollable-bot-message-content {
                    max-height: 240px;
                    /* Set a maximum height for scrolling */
                }

                /* Style profile images */
                .user-profile-img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid #E5E3DD;
                    align-self: flex-start;
                    margin-top: 15px;
                    margin-left: 10px;
                    margin-block-end: 10px;

                }

                .bot-profile-img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid #E5E3DD;
                    align-self: flex-start;
                    margin-top: 15px;
                    margin-right: 10px;
                    margin-block-end: 10px;
                }
            }

            .alice-carousel__stage-item {
                //   margin-right: 10px;
                width: 100%;
                gap: 10px;
                line-height: initial;

                background-color: #f0f0f0;
                border: 1px solid #ddd;
                //   padding: 20px;
                text-align: center;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                visibility: visible;
            }
        }
    }

    .landing-page-main .landing-page-second-part .container-button {
        display: flex;
        justify-content: center;
        // padding-top: 70px !important;
    }

    .carousel-container-youtube {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 1px;
        margin-right: 1px;
        cursor: pointer;

        .thumbnail {
            width: 400px !important;
            height: 500px !important;
            gap: 40px !important;
            border-radius: 8px;
            max-width: 100%;

            .thumbnail:last-child {
                margin-right: 10px !important;
            }

            img {
                width: 90% !important;
                height: 90% !important;
                gap: 40px !important;
                object-fit: cover;
                transition: transform 0.03s ease;

            }

            &:hover {
                transform: scale(1.03);
            }
        }

        .landing-page-main .landing-page-second-part .carousel {
            padding-left: 5px !important;
            padding-right: 5px !important;
            margin: 0px !important;
            width: 95% !important;
        }

        .alice-carousel__stage-item {
            margin-right: 1px !important;
            margin-bottom: 1px !important;
            gap-bottom: 20px;
            width: 95% !important;

        }

    }


    .landing-page-main .landing-page-second-part .marshall-image-container2 {
        height: auto;

        img {
            top: auto;
            height: auto;
            transform: none;
            position: static;
            width: 100%;
            margin-top: 20px;
        }
    }

    .landing-page-main .landing-page-second-part .marshall-desc {
        font-size: 19px;
        line-height: 30px;
        font-weight: 500;
    }

    .landing-page-main .landing-page-second-part .social-icons {
        img {
            height: 35px;
        }

    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-header {
        font-size: 28px;

        .gen-ai-three-points {
            flex-direction: column !important;
            margin-left: 20px !important;
            margin-right: 10px !important;
            justify-content: left !important;
            align-items: flex-start !important;

            .each-point {
                font-size: 19px;
                gap: 20px;
                line-height: 29px;

                .genai-icon {
                    height: 56px;
                    width: 56px;
                }
            }
        }
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-text1 {
        font-size: 18px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-text2 {
        font-size: 26px;
        font-weight: 600;
        height: 45px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-text3 {
        font-size: 18px;
        margin-top: 0px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-description {
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 36px;
        font-size: 19px;
        line-height: 28px;
        margin-right: 0px;
        margin-left: 0px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-founders {
        padding-right: 32px;
        padding-left: 32px;
        gap: 10px;
    }

    .landing-page-main .landing-page-second-part .footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }


    // }
    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-founders .person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-founders .person .name {
        height: 48px;
        width: 113px;
        font-size: 16px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-founders .person .role {
        font-size: 12px;
        width: 126px;
    }
}

/* For Tablet Devices (768px to 1024px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

    .landing-page-main .landing-page-second-part.landing-page-transition-part .marshall-text {
        font-size: 30px;
    }

    .landing-page-main .landing-page-second-part.landing-page-transition-part .marshall-text2 {
        font-size: 50px;
        line-height: 60px;
    }

    .landing-page-main .landing-page-second-part .landing-page-text-part {
        padding: 30px;
    }

    .landing-page-main .landing-page-second-part .mg-image-container {
        width: 800px !important;
        height: 300px !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .landing-page-main .landing-page-second-part .text-and-button {
        height: 300px !important;
        margin-right: 10px !important;
        margin-left: 15px !important;
        transform: translateY(-14px);
    }

    .landing-page-main .landing-page-second-part .text-content {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .landing-page-main .landing-page-second-part .lets-chat-button {
        padding: 5px 5px !important;
        margin-top: 5px !important;
        transform: translateY(-5px) !important;
        height: 30px !important;
        width: 130px !important;
    }

    .landing-page-main .landing-page-second-part .points {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 22px;
    }

    .landing-page-main .landing-page-second-part .points-box {
        width: 100%;
        height: 80px;
    }

    .landing-page-main .landing-page-second-part .image-container {
        width: 10%;
    }

    .landing-page-main .landing-page-second-part .right-text {
        font-size: 20px;
    }

    .landing-page-main .landing-page-second-part .box-contents {
        height: 80px;
    }

    .landing-page-main .landing-page-second-part .three-points {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .each-point {
            font-size: 16px;
        }
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-three-points {
        flex-direction: column !important;
        margin-right: 0px;
        justify-content: left;
        align-items: flex-start;

        .each-point {
            font-size: 16px;
            gap: 30px;

            .genai-icon {
                height: 56px;
                width: 56px;
            }
        }
    }

    .landing-page-main .landing-page-second-part .three-points {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .first-point {
            align-items: center;
            font-size: 19px;
            font-weight: 500;
        }

        .each-point {
            font-size: 19px;
            font-weight: 500;
        }
    }

    .landing-page-main .landing-page-second-part .threeQuestionsBox {
        display: flex !important;
        flex-direction: column;
        margin: auto;
        width: 100% !important;
        max-height: 3190px !important;
        height: auto !important;
        overflow-y: hidden !important;
        gap: 0px;
    }

    .landing-page-main .landing-page-second-part .threeQuestionsBox .signInNotes {
        width: calc(100% - 150px);
    }

    .landing-page-main .landing-page-second-part .threeQuestionsBox .signInNotes .signin-points {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .first-point,
        .second-point,
        .third-point {
            align-items: center;
            font-size: 19px;
            font-weight: 500;
        }
    }
}

@media (max-width: 1000px) and (max-height: 500px) {
    .landing-page-main .landing-page-second-part .landing-page-transition-part .marshall-text {
        font-size: 24px;
        margin-top: 38px;
    }

    .landing-page-main .landing-page-second-part .landing-page-transition-part .marshall-text2 {
        font-size: 40px;
        line-height: 45px;
    }
}

@media screen and (max-width: 480px) {
    .gen-ai-three-points {
        flex-direction: column !important;
        margin-left: 20px !important;
        margin-right: 10px !important;
        justify-content: left !important;
        align-items: flex-start !important;
        gap: 30px !important;
        ;

        .each-point {
            font-size: 19px;
            gap: 30px;
            line-height: 29px;

            .genai-icon {
                height: 56px;
                width: 56px;
            }
        }
    }

    .fractal-description {
        font-size: 19px;
        font-style: italic;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: center;
        margin: 20px 35px 50px 35px !important;
        line-height: 34px;
    }
}
.chat-container{
    .chat-header{
        text-align: center;
        font-size: 30px;
        margin-top: 36px;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
}
@media screen and (max-width: 480px) {

    .landing-page-main .landing-page-second-part .chat-container .chat-card1,
    .landing-page-main .landing-page-second-part .chat-container .chat-card2,
    .landing-page-main .landing-page-second-part .chat-container .chat-card3 {
        padding: 10px 14px 14px 14px !important;
        /* Top, right, bottom, left */
        margin: 0px !important;
        width: 95% !important;
    }

    .landing-page-main .landing-page-second-part .chat-container .alice-carousel__stage-item {
        margin-right: 1px !important;
        margin-bottom: 1px;
        gap-bottom: 40px;
        width: 100%;

    }
}

//For iPad Pro Tablet and tablet devices with similar width
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .landing-page-main .landing-page-second-part .mg-image-container {
        width: 900px !important;
    }

    .landing-page-main .landing-page-second-part .lets-chat-button {
        padding: 5px 5px !important;
        margin-top: 5px !important;
        transform: translateY(-5px) !important;
    }

    .landing-page-main .landing-page-second-part .points {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 22px;
    }

    .landing-page-main .landing-page-second-part .points-box {
        width: 100%;
        height: 80px;
    }

    .landing-page-main .landing-page-second-part .image-container {
        width: 10%;
    }

    .landing-page-main .landing-page-second-part .right-text {
        font-size: 20px;
    }

    .landing-page-main .landing-page-second-part .box-contents {
        height: 80px;
    }
}

/*For mobile devices with smaller screens*/
@media only screen and (max-width: 399px) {

    // .landing-page-main .landing-page-second-part .landing-page-transition-part .marshall-image{
    //     overflow: unset;
    // }
    .landing-page-main .landing-page-header {
        padding: 20px 12px 20px 12px;
    }

    .landing-page-main .landing-page-header .mg-logo img {
        width: 190px
    }

    .landing-page-main .landing-page-second-part .landing-page-transition-part {
        background-image: url('../../assets/images/bg-img-mob.png');
    }

    .landing-page-main .landing-page-second-part .landing-page-transition-part .marshall-text {
        font-size: 30px;
    }

    .landing-page-main .landing-page-second-part .landing-page-transition-part .marshall-text2 {
        font-size: 52px;
        line-height: 60px;
        font-weight: 700;
    }

    .landing-page-main .landing-page-header .right-pane .lets-chat-button {
        width: 140px;
        height: 36px;
        font-weight: 500;
    }

    .landing-page-main .landing-page-header .right-pane .powered-by-logo {
        // bottom: -20px;
    }

    .landing-page-main .landing-page-second-part .landing-page-text-part {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .landing-page-main .landing-page-second-part .mg-image-container {
        height: 150px !important;
        width: 310px !important;
        margin-left: 6px !important;
        margin-right: 6px !important;
        margin-bottom: 6px;
    }

    .landing-page-main .landing-page-second-part .text-and-button {
        padding-top: 6px;
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .landing-page-main .landing-page-second-part .landing-page-text-part .text-content {
        font-size: 16px;
        line-height: 18px;
    }

    .landing-page-main .landing-page-second-part .landing-page-text-part .lets-chat-button {
        height: 40px !important;
        width: 140px !important;
    }

    .landing-page-main .landing-page-second-part .points {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 14px;
    }

    .landing-page-main .landing-page-second-part .points-box {
        width: 100%;
    }

    .landing-page-main .landing-page-second-part .right-text {
        font-size: 15px;
    }

    .landing-page-main .landing-page-second-part .three-points {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 24px;

        .first-point {
            align-items: center;
            font-size: 19px;
            font-weight: 500;
            line-height: 28px;

        }

        .each-point {
            font-size: 19px;
            font-weight: 500;
            line-height: 28px;
        }
    }

    .landing-page-main .landing-page-second-part .name-marshall {
        font-size: 64px;
        line-height: 64px;
    }

    .landing-page-main .landing-page-second-part .examples-header {
        font-size: 32px;
        font-weight: 500;
    }

    .threeQuestionsBox {
        display: flex !important;
        flex-direction: column;
        margin: auto;
        width: 100% !important;
        max-height: 3190px !important;
        height: auto !important;
        overflow-y: hidden !important;
        gap: 0px;
        opacity: 1;
        /* Make visible */
        background: #f2f2f2;
        border: 1px solid #D5D5D5 !important;
        padding: 0px;
        border-radius: 8px;
        margin-bottom: 28px !important;
        position: relative;

        .free-three-questions-container {
            display: flex;
            justify-content: center;
            margin-top: 35px !important;

            .free-three-questions {
                display: flex;
                font-family: Araboto-Medium;
                font-size: 28px !important;
                font-weight: 500 !important;
                line-height: 1.5;
                text-align: center;
                color: #00490A;
                background-color: #f2f2f2;
                padding: 10px 20px;
                border-radius: 8px;
                margin-top: 30px;
            }

            .three_questions_arrow {
                display: flex;
                align-items: center;
                width: 43.58px;
                height: 50.84px;
                margin-top: 70px !important;
                margin-right: 10px;
                padding: 0px;
                gap: 0px;
                opacity: 1;
            }
        }

        .chat-v2-screen {
            height: calc(100vh - 100px) !important;
            margin-top: 0px !important;
            display: flex !important;
            margin-bottom: 0px !important;
            align-items: center !important;
            flex-direction: column !important;
            justify-content: center !important;
        }

        .chat-v2-screen .big-container {
            display: flex;
            height: calc(100vh - 200px) !important;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 0px !important;
        }

        .chat-v2-screen .big-container .container {
            width: 90% !important;
            height: calc(100vh - 200px) !important;
            max-width: 992px;
            margin-top: 0px !important;
            background-color: white;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }


        .signInNotes {
            background: #EBF9F2;
            border: 2px solid #00490A !important;
            width: 90% !important;
            margin: 0px auto;
            gap: 0px;
            margin-top: 0px !important;
            margin-bottom: 30px !important;
            padding: 0px;
            border-radius: 8px;
            opacity: 1;
            /* Make visible */


            .signInNotesInnerBox {
                width: 100%;
                height: auto;
                position: relative;
                gap: 0px !important;
                margin-bottom: 10px;
                border-radius: 8px;
                opacity: 1;
                /* Make visible */
                display: flex;
                justify-content: center;

                button {
                    background: #0987C5;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    border-radius: 5px;
                    cursor: pointer;
                    align-content: center;
                }
            }

            .signin-points {
                display: flex;
                flex-direction: column;
                padding: 20px;
                margin-bottom: 5px !important;
                gap: 10px !important;
                /* Adjusted gap */
                opacity: 1;
                /* Make visible */

                .first-point .second-point,
                .third-point {
                    display: flex;
                    gap: 8px;
                    font-weight: 500;
                    color: #161616;
                    font-size: 14px;
                    line-height: 1.5;
                }

                .tick-mark {
                    height: 28px;
                    width: 28px;
                    margin-top: 4px;
                }
            }
        }
    }

    .carousel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 10px;
        cursor: pointer;
        transition: transform 0.3s ease;

        .chat-card1,
        .chat-card2,
        .chat-card3 {
            background-color: #F3F3F3;
            margin: 20px;
            padding: 10px 42px 14px 10px !important;
            /* Top, right, bottom, left */
            margin: 10px;
            width: 300px;
            height: 500px;
            border-radius: 8px;
            border: 1px solid #000000;
            /* Add a black border */
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            max-width: 100%;
            /* Limit the width of chat cards */
            // overflow-y: scroll; /* Enable vertical scrolling */
            margin-bottom: 10px;

            .user-message {
                // position:sticky;
                top: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }

            .user-message p {
                background-color: #00490A;
                color: white;
                /* Set text color to white */
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 10px;
                max-width: 100%;
                overflow-wrap: break-word;
            }

            .bot-message {
                background-color: #f3f3f3;
                display: flex;
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 10px;
                max-width: 100%;
                overflow-wrap: break-word;
                // overflow-y: auto; /* Make the bot message scrollable */
                max-height: 230px;
                /* Set a maximum height for scrolling */

                .message-footer {
                    background-color: #E5E3DD;
                    // display:flex;
                    margin-bottom: 10px;
                    // padding: 10px;
                    border-radius: 10px;
                    max-width: 100%;
                    height: 440px;
                    overflow-wrap: break-word;
                    // overflow-y: auto; /* Make the bot message scrollable */
                    max-height: 200px;

                    /* Set a maximum height for scrolling */
                    .bot-message-content {
                        background-color: #E5E3DD;
                        display: flex;
                        margin-bottom: 1px;
                        padding: 10px;
                        border-radius: 10px;
                        max-width: 100%;
                        overflow-wrap: break-word;
                        overflow-y: auto;
                        /* Make the bot message scrollable */
                        max-height: 180px;
                        /* Set a maximum height for scrolling */

                    }

                    .chat-bot-footer {
                        // display:block;
                        justify-content: center;
                        /* Align buttons to the right */
                        bottom: 1px;
                        /* Align it to the bottom */
                        align-items: center;
                        padding: 8px, 20px, 5px, 100px;
                        /* Top, right, bottom, left */
                        margin: 5px;
                        // margin-bottom: 20px;
                        background-color: #E5E3DD;
                        border: 1px solid #ebeae8;
                    }

                    .button-container {
                        display: flex;
                        justify-content: flex-end;
                        gap: 10px;
                        /* Add spacing between symbols */
                        justify-content: flex-end;
                        background-color: #f3f3f3;

                        .thumbs_up,
                        .thumbs_down .copy {
                            width: 24px;
                            /* Adjust the image size as needed */
                            height: 24px;
                        }

                    }
                }

                .scrollable-bot-message-content {
                    max-height: 240px;
                    /* Set a maximum height for scrolling */
                }

                /* Style profile images */
                .user-profile-img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid #E5E3DD;
                    align-self: flex-start;
                    margin-top: 15px;
                    margin-left: 10px;
                    margin-block-end: 10px;

                }

                .bot-profile-img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid #E5E3DD;
                    align-self: flex-start;
                    margin-top: 15px;
                    margin-right: 10px;
                    margin-block-end: 10px;
                }
            }

            .alice-carousel__stage-item {
                //   margin-right: 10px;
                width: 100%;
                gap: 10px;
                line-height: initial;

                background-color: #f0f0f0;
                border: 1px solid #ddd;
                //   padding: 20px;
                text-align: center;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                visibility: visible;
            }
        }
    }

    .landing-page-main .landing-page-second-part .container-button {
        display: flex;
        justify-content: center;
        // padding-top: 70px !important;
    }

    .carousel-container-youtube {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 1px;
        margin-right: 1px;
        cursor: pointer;

        .thumbnail {
            width: 400px !important;
            height: 500px !important;
            gap: 40px !important;
            border-radius: 8px;
            max-width: 100%;

            .thumbnail:last-child {
                margin-right: 10px !important;
            }

            img {
                width: 90% !important;
                height: 90% !important;
                gap: 40px !important;
                object-fit: cover;
                transition: transform 0.03s ease;

            }

            &:hover {
                transform: scale(1.03);
            }
        }

        .landing-page-main .landing-page-second-part .carousel {
            padding-left: 5px !important;
            padding-right: 5px !important;
            margin: 0px !important;
            width: 95% !important;
        }

        .alice-carousel__stage-item {
            margin-right: 1px !important;
            margin-bottom: 1px !important;
            gap-bottom: 20px;
            width: 95% !important;

        }

    }


    .landing-page-main .landing-page-second-part .marshall-image-container2 {
        height: auto;

        img {
            top: auto;
            height: auto;
            transform: none;
            position: static;
            width: 100%;
            margin-top: 20px;
        }
    }

    .landing-page-main .landing-page-second-part .marshall-desc {
        font-size: 19px;
        line-height: 30px;
        font-weight: 500;
    }

    .landing-page-main .landing-page-second-part .social-icons {
        img {
            height: 35px;
        }

    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-header {
        font-size: 28px;

        .gen-ai-three-points {
            flex-direction: column !important;
            margin-left: 20px !important;
            margin-right: 10px !important;
            justify-content: left !important;
            align-items: flex-start !important;

            .each-point {
                font-size: 19px;
                gap: 20px;
                line-height: 29px;

                .genai-icon {
                    height: 56px;
                    width: 56px;
                }
            }
        }
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-text1 {
        font-size: 18px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-text2 {
        font-size: 26px;
        font-weight: 600;
        height: 45px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .gen-ai-text3 {
        font-size: 18px;
        margin-top: 0px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-description {
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 36px;
        font-size: 19px;
        line-height: 28px;
        margin-right: 0px;
        margin-left: 0px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-founders {
        padding-right: 32px;
        padding-left: 32px;
        gap: 10px;
    }

    .landing-page-main .landing-page-second-part .footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }


    // }
    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-founders .person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-founders .person .name {
        height: 48px;
        width: 113px;
        font-size: 16px;
    }

    .landing-page-main .landing-page-second-part .gen-ai-solutions-container .fractal-founders .person .role {
        font-size: 12px;
        width: 126px;
    }
}