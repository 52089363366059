.large-text {
    font-size: 20px; /* Adjust the font size as needed */
  }
.para-text{
    font-size: 19px;
  }
.ul{
    list-style-type: disc; /* Specifies the type of bullet point */
    margin-left: 60px; /* Adjust margin as needed */
  }
.ul-inside{
    list-style-type: none;
    margin-left: 30px; /* Adjust margin as needed */
  }
.cookieContainer{
    margin-left: 20px;
}
.highlighted-link {
  background-color: yellow;
}

