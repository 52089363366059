@import "../../globals/styles/variables.less";

.login {
  height: 100%;
  display: flex;
  background-color: rgba(#e8e4d8, 0.2);
  // background-image: url("../../assets/images/mg-thumbprint.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .mg-left-container {
    .mg-left-image {
      height: 100%;
    }
  }

  .login-right-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .inside-container {
      border: 1px solid #236B48;
      border-radius: 16px;
      // min-height: 504px;
      min-width: 504px;

      .card-first-part {
        background: #ECF9F2;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        min-height: 82px;
        display: flex;
        justify-content: center;
        align-items: center;

        .vertical-line {
          border: 0.9px;
          width: 18px;
          height: 62px;
          border-right: 1px solid #000;
          /* background: red; */
          margin-right: 10px;
          margin-left: 10px;
          opacity: 10%;
        }

        .logo-image {
          width: 110px;
          height: 45px;
          object-fit: contain;
        }


      }

      .card-second-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;

        .google-login {
          margin-top: 40px;
        }

        .sign-in-line {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 16px 0px 16px 0px;
          gap: 12px;

          .sign-in-text {
            opacity: 50%;
            color: #000;
            font-size: 12px
          }

          .horizontal-line {
            border: 1px solid rgba(73, 78, 88, 0.2);
            width: 72px;

          }
        }

        .form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 72%;

          .text-input {
            .Mui-focused {
              outline: #236B48;
            }
          }

          .label-text {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            opacity: 0.7;

          }

          .checkbox-container {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            height: 18px;
            gap: 10px;

            .checkbox {
              height: 16px;
              width: 16px;
              border-radius: 2px;
              border: 1px solid var(--neutral-5, #D9D9D9);
              background: var(--green, #236B48);
              cursor: pointer;
            }

            label {
              color: rgba(0, 0, 0, 0.45);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 183.333% */
            }
          }

          .form-field {
            width: 100%;
            border-radius: 4px;
            background-color: white;
            margin-bottom: (@gutter * 0.75);

            .button {
              border-radius: 4px;
              // background-color: var(--green, #236B48);
              box-shadow: 0px 2.56825px 0px 0px rgba(0, 0, 0, 0.04);
              color: #fff;
            }

            // &.button-field {
            //   margin-top: @gutter;
            // }
          }

        }

        .collapse-bar {
          margin-bottom: 12px;
        }

        .bottom-container {
          display: flex;
          justify-content: space-between;
          align-items: start;
          width: 72%;
          margin-bottom: 40px;
          color: var(--green, #236B48);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.overlay-for-google-signin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading-icon {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  p {
    color: white;
  }
}

.terms-login {
  color: #595959;
  text-transform: none;
  padding-right: 30px;
  font-size: smaller;

  &:hover {
    color: #551A8B;
  }
}

.faText-login {
  color: #595959;
  text-transform: none;
  padding-left: 30px;
  font-size: smaller;
}

// @media screen and (max-width: 992px) {
//   .login {
//     flex-flow: column;
//     align-items: center;
//     justify-content: center;

//     .containers {
//       height: 540px;
//       border-top: 4px solid #00490a;

//       .hero-image-container {
//         display: none;
//       }

//       .form-container {
//         .image {
//           display: block;
//           position: absolute;
//           top: -60px;
//           left: 50%;
//           transform: translateX(-50%);

//           img {
//             width: 120px;
//             height: 120px;
//             border-radius: 100%;
//             border: 4px solid #00490a;
//           }
//         }

//         .logo {
//           margin-top: (@gutter * 3);
//         }
//       }
//     }

//   }
// }

// @media screen and (max-width: 480px) {
//   .login {
//     max-width: 380px;
//     margin: auto;
//   }

//   .containers {
//     .form-container {
//       max-width: 380px;
//     }
//   }
// }

@media screen and (max-width: 992px) {
  .login {
    .mg-left-container {
      display: none;
    }
  }

  .terms-login {
    color: #595959;
    text-transform: none;
    padding-right: 10px;
    font-size: 10px;

    &:hover {
      color: #551A8B;
    }
  }

  .faText-login {
    color: #595959;
    text-transform: none;
    padding-left: 10px;
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .login {
    .mg-left-container {
      display: none;
    }
  }

  .terms-login {
    color: #595959;
    text-transform: none;
    padding-right: 10px;
    font-size: 10px;

    &:hover {
      color: #551A8B;
    }
  }

  .faText-login {
    color: #595959;
    text-transform: none;
    padding-left: 10px;
    font-size: 10px;
  }
}