@import "../../globals/styles/variables.less";

.page-not-found-container {
  width: 768px;
  height: 1024px;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .message-box {
    display: flex;
    width: 480px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .message {
      margin-bottom: @gutter;
    }
    .button {
      font-size: 18px;
      font-weight: bold;
      border-radius: 12px;
      padding: @gutter (@gutter * 3);
      text-transform: capitalize;
    }
  }

}