@import "../../../globals/styles/variables.less";

.contact-us-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .marshall-standing-icon {
        width: 40%;
        height: auto;
    }

    .content {
        width: 50%;
        padding-left: 20px;

        .heading {
            margin-bottom: 20px;
        }

        .access-marshalls-extensive-container {
            font-size: 24px;
            margin-bottom: 12px;
            word-wrap: break-word;

            .marshalls {
                font-size: 30px;
                background: linear-gradient(180deg, #efb345, #896728);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .points {
            display: inline;
            .point {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .frame-item {
                    width: 14px;
                    height: 14px;
                    margin-right: 8px;
                }

                .description {
                    font-size: 16px;
                }
            }
        }

        .contact-us-wrapper {
            margin-top: 20px;
            cursor: pointer;
            text-align: center;
            font-size: 18px;
        }
    }

    .contact-us {
        position: relative;
    }
    .contact-us-parent {
        cursor: pointer;
        width: 200px;
        position: relative;
        border-radius: 100px;
        background: linear-gradient(180deg, #efb345, #896728);
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 11px 30px;
        box-sizing: border-box;
        text-align: left;
        font-size: 20px;
        font-weight: 600;
        color: #17392b;
        font-family: Poppins;
    }
}



@media (max-width: 480px) {
    .contact-us-container {
        flex-direction: column;
        align-items: center;
        text-align: center;

        .marshall-standing-icon {
            width: 80%;
            height: auto;
        }

        .content {
            width: 100%;
            padding-left: 0;
        }

        .heading {
            font-size: 20px;
        }

        .points {
            .point {
                flex-direction: column;
                align-items: center;
                margin-bottom: 12px;

                .frame-item {
                    margin-bottom: 8px;
                }

                .description {
                    font-size: 14px;
                }
            }
        }

        .contact-us-wrapper {
            font-size: 16px;
            padding: 12px;
        }
    }

    .contact-us {
        position: relative;
    }
    .contact-us-parent {
        cursor: pointer;
        width: 150px;
        position: relative;
        border-radius: 100px;
        background: linear-gradient(180deg, #efb345, #896728);
        height: 22px;
        display: inline;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        box-sizing: border-box;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        color: #17392b;
        font-family: Poppins;
    }
}
