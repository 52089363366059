@import "../../styles/variables.less";

.page-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  padding: (@gutter * 0.5) (@gutter * 0.75);

  .logos {
    display: flex;
    align-items: center;

    .mg-logo {
      margin-right: @gutter;

      img {
        width: 110px;
      }
    }

    @media screen and (max-width: 768px) {
      .mg-logo {
        img {
          width: 84px;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .extra {
      display: flex;
      margin-right: (@gutter * 0.25);

      button {
        margin-right: (@gutter * 0.75);

        &:last-child {
          margin-right: 0;
        }

        span.label {
          font-size: 14px;
          padding: (@gutter * 0.10);
        }
      }
    }
  }
}

.header-menu {
  .MuiPaper-root {
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  }

  .menu-item {
    .menu-item-text {
      span {
        color: @secondaryTextColor;
        font-size: 14px !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .page-header {
    .logos {
      .mg-logo {
        margin-right: (@gutter * 0.5) !important;
      }
    }
  }

  .right {
    transform: translateX(-10px);

    .extra {
      button {
        margin-right: 10px;
      }
    }
  }
}