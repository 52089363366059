.chat-input-container {
    display: flex;
    align-items: center;
    background-color: #1f1f1f;
    border-radius: 16px;

    .chat-input-field {
        flex: 1;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 1px 6px 1px #dedede;
        
        .MuiInputBase-root {
            padding: 8px;
        }

        textarea {
            padding-right: 10px;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .send-button {
        color: #525252;
        background-color: transparent;
        border-radius: 0;

        &.active {
            color: white;
            background-color: mediumseagreen;
            border-radius: 30%;
        }
    }

    .mic-toggle-button {
        background-color: #236B48;
        padding: 14px 16px;
        border-radius: 8px;
        margin-left: 5px;
    }
    .mic-toggle-button:hover {
        background-color: #185f3d;
    }
    .video-cut {
        background-color: red;
        padding: 14px 14px;
        border-radius: 8px;
        margin-left: 5px;
        border-radius: 50%;
        color: white;
    }
    .video-cut:hover {
        background-color: rgb(224, 9, 9);
    }

    .recording-container {
        border: 1px solid #acacac;
        height: 121px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        width: 100%;

        .canvas-container {
            width: 100%;
        }
    }
}

