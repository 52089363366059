@import "../../../globals/styles/variables.less";


.main-container {
    display: flex;
    min-height: 100%;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: rgba(#e8e4d8, 0.2);

    .msg-container {
        flex: 1;
        width: 100%;
        min-height: 100%;
        margin-left: 250px;
        margin-right: 250px;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .message {
        padding: (@gutter * 0.75);

        .top {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: (@gutter * 0.25);

            .image {
                margin-right: (@gutter * 0.5);


                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    border: 1px solid @borderColor;

                }
            }

            .image-user {
                margin-left: (@gutter * 0.5);


                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    border: 1px solid @borderColor;

                }
            }

            .text-t {
                padding-bottom: 15px;
            }

            .answer-block {
                background-color: #E6E3DD;
                padding: 10px;
                border-radius: 5px;
                color: #236B48;
                padding-right: (@gutter * 0.5);
                width: 100%;
                border-radius: 5px;
                display: inline-block;
                overflow-wrap: break-word;
            }

            .user-text {
                background-color: #236B48;
                padding: 10px;
                border-radius: 5px;
                color: white;
                padding-right: (@gutter * 0.5);
                width: fit-content;
                border-radius: 5px;
                display: inline-block;
                overflow-wrap: break-word;
            }

        }

        .top-user {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: (@gutter * 0.25);

            .image {
                margin-right: (@gutter * 0.5);


                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    border: 1px solid @borderColor;

                }
            }

            .image-user {
                margin-left: (@gutter * 0.5);


                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    border: 1px solid @borderColor;

                }
            }

            .user-text {
                background-color: #00490A;
                padding: 10px;
                border-radius: 5px;
                color: white;
                padding-right: (@gutter * 0.5);
                width: fit-content;
                border-radius: 5px;
                display: inline-block;
                overflow-wrap: break-word;
            }

            &.sent {
                background-color: white;

                .image {
                    img {
                        padding: 4px;
                    }
                }
            }

            &.received {
                background-color: white;
            }

        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .main-container {
        .msg-container {
            margin-left: 50px;
            margin-right: 50px;

        }
    }
}
@media screen and (max-width: 480px) {
    .main-container {
        .msg-container {
            margin-left: 10px;
            margin-right: 10px;

        }
    }
}
