@import "../../globals/styles/variables.less";


.paperContainer {
    min-height: 100%;
    min-width: 100%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
}

.header {
    display: flex;
    align-items: center;
}

.logo {
    margin-right: 20px; 
    max-width: 400px; 
    max-height: 400px; 
}

.line {
    flex-grow: 1;
    background-color: black;
    height: 4px; 
    margin: 0;
}

.contentContainer {
    flex-grow: 1; 
}

.indent1 {
    margin-left: 20px;
}

.indent2{
    margin-left: 40px;
}

.indent3 {
    margin-left: 60px;
}

.no-login-screen {
    max-width: 80%;
    margin: auto;
    display: block !important;
    padding: @gutter;
  }